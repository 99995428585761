<i18n locale="th" lang="yaml" >
	service.reason : "สาเหตุ"
</i18n>

<template>
	<MyTooltip :trigger="trigger"
		display-mode="white" @click.stop>
		<div slot="title" :class="tooltipCssClasses">
			<div class="description">
				<component :is="icon" alt='' title=''/> {{$tenum('service.status', status + '.description')}}
			</div>
			<div v-if="$notEmpty(reason)" class="reason">
				<b>{{$t('service.reason')}}</b> :	{{reason}}
			</div>
		</div>

		<span :class="cssClasses">
			{{$tenum('service.status', status)}}<component :is="icon" v-if="!hideIcon" alt='' title=''/>
		</span>
	</MyTooltip>
</template>

<script>
import NewIcon from "vue-material-design-icons/NewBox.vue"
import InvestigateIcon from "vue-material-design-icons/CrosshairsGps.vue"
import ReopenIcon from "vue-material-design-icons/SyncAlert.vue"
import CloseIcon from "vue-material-design-icons/ClipboardCheckOutline.vue"
import PendingIcon from "vue-material-design-icons/PauseCircleOutline.vue"
import CancelIcon from "vue-material-design-icons/Cancel.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import 'vue-material-design-icons/styles.css';


const STATUS_LIST = {
	'new' : {
		icon : NewIcon ,
	} ,
	'in_progress' : {
	icon : InvestigateIcon ,
	} ,
	'pending' : {
	icon : PendingIcon,
	} ,
	'pre_delivery' : {
		icon : ReopenIcon ,
	} ,
	'wait_delivery' : {
		icon : CloseIcon,
	} ,
	'close' : {
		icon : CloseIcon,
	} ,
	'cancel' : {
	icon : CancelIcon,
	} ,


}
export default {
	components : {
		MyTooltip
	} ,
	props : {
		status : {
			type : String,
			default : ''
		} ,
		reason : {
			type : String,
			default : undefined,
		} ,
		hideIcon : {
			type : Boolean,
			default : true,
		} ,
		tagStyle : {
			type : Boolean,
			default : false,
		} ,
		trigger : {
			type : String,
			default : 'click|hover'
		} ,
	} ,
	computed : {
		icon() {
			if (this.status in STATUS_LIST) {
				return STATUS_LIST[this.status].icon
			} else {
				return undefined
			}
		} ,
		cCssStatusSuffix() {
			if (this.status in STATUS_LIST) {
				return "-" + (STATUS_LIST[this.status].cssSuffix ? STATUS_LIST[this.status].cssSuffix : this.status)
			} else {
				return ''
			}
		} ,
		cssClasses() {
			return [
				"service-status" ,
				"service-status" + this.cCssStatusSuffix,
				(['pending', 'wait_delivery'].includes(this.status)) ? `service-status-outline${this.cCssStatusSuffix}`:"service-status-bg" + this.cCssStatusSuffix,
				{
					"service-status-tag" : this.tagStyle
				}
			]
		} ,
		tooltipCssClasses() {
			return "service-status-tooltip-content service-status-tooltip" + this.cCssStatusSuffix;
		}
	} ,

}
</script>

<style lang="less">
.service-status-mixins(@status,@color,@text-color)  {
	.service-status-@{status} {
		color : @color;
		&.service-status-tag {
			color : @text-color;
			background : @color;
			&:hover {
				opacity: 0.85;
			}
		}
	}
	.service-status-tooltip-@{status} {
		.description {
			color : @color;
		}
	}
	.service-status-bg-@{status}{
		background-color: @color;
		padding: 3px 5px;
		border-radius: 5px;
		color:white;
	}
	.service-status-outline-@{status}{
		border: 1px solid @color;
		padding: 3px 5px;
		border-radius: 5px;
		color: @color;
	}
}

.service-status {
	font-weight: normal;
	font-family: @font-family;
	white-space: nowrap;
	&.service-status-tag {
		display : inline-block;
		padding : 0 7px;
		border-radius : @border-radius-base;
		font-size : 12px;
		line-height: 20px;
	}
}
.service-status-tooltip-content {
	padding: 2px 4px;
	.reason {
		border-top: 1px dashed @border-color-base;
		margin-top: 0;
		padding-top : 6px;
		font-size: 12px;
	}
}

.service-status-mixins(new,@status-new-color,@white);
.service-status-mixins(in_progress,@status-in-progress-color,@white);
.service-status-mixins(pending,@status-pending-color,@white);
.service-status-mixins(pre_delivery,@status-pre-delivery-color,@white);
.service-status-mixins(wait_delivery,@status-wait-delivery-color,@white);
.service-status-mixins(close,@status-close-color,@white);
.service-status-mixins(cancel,@status-cancel-color,@white);
</style>
